body,
html {
    background-color: var(--surface);
    color: var(--text-medium-emphasis);
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;

    ::-webkit-scrollbar {
        width: 11px;
        height: 11px;
    }

    ::-webkit-scrollbar-track {
        background: var(--color-00);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--color-53);
        border-radius: 6px;
        border: 2px solid var(--color-00);
    }

    ::-webkit-scrollbar-corner {
        background: var(--color-00);
    }
    .vc-light-theme {
        ::-webkit-scrollbar-track {
            background: var(--color-d0);
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--color-e5);
            border: 2px solid var(--color-d0);
        }

        ::-webkit-scrollbar-corner {
            background: var(--color-ed);
        }
    }
}

/* Common CSS   */

a {
    color: var(--vc-link-txt);
    text-decoration: none;
}


section.ngx-json-viewer {
    section.segment {
        section.segment-main {
            span.segment-key {
                color: var(--vc-ngx-json-viewer-clr) !important;
            }
        }
    }
}
