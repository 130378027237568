$font-family-poppins: 'Poppins', sans-serif;

@mixin display-l {
    font-size: 64px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin display-m {
    font-size: 56px;
    font-family: $font-family-poppins;
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin display-s {
    font-size: 48px;
    font-family: $font-family-poppins;
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin display-xs {
    font-size: 42px;
    font-family: $font-family-poppins;
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin headline-l {
    font-size: 24px;
    font-family: $font-family-poppins;
    font-weight: 600;
    font-style: normal;
    line-height: 32px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin headline-m {
    font-size: 20px;
    font-family: $font-family-poppins;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin headline-s {
    font-size: 18px;
    font-family: $font-family-poppins;
    font-weight: 600;
    font-style: normal;
    line-height: 24px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin title-l {
    font-size: 16px;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin title-m {
    font-size: 15px;
    font-family: $font-family-poppins;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin title-s {
    font-size: 15px;
    font-family: $font-family-poppins;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: none;
}

@mixin body-m-1 {
    font-size: 14px;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin body-m-2 {
    font-size: 14px;
    font-family: $font-family-poppins;
    font-weight: 600;
    font-style: normal;
    line-height: 20px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin body-s {
    font-size: 12px;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin body-s-2 {
    font-size: 12px;
    font-family: $font-family-poppins;
    font-weight: 700;
    font-style: normal;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin label-l {
    font-size: 14px;
    font-family: $font-family-poppins;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin label-s {
    font-size: 12px;
    font-family: $font-family-poppins;
    font-weight: 500;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: none;
}

@mixin input-text {
    font-size: 14px;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-style: normal;
    line-height: 14px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
}

@mixin input-label {
    font-size: 10px;
    font-family: $font-family-poppins;
    font-weight: 400;
    font-style: normal;
    line-height: 12px;
    text-decoration: none;
    letter-spacing: normal;
    text-transform: none;
}

html,
body {
    .display-l {
        @include display-l;
    }

    .display-m {
        @include display-m;
    }

    .display-s {
        @include display-s;
    }

    .display-xs {
        @include display-xs;
    }

    .headline-l {
        @include headline-l;
    }

    .headline-m {
        @include headline-m;
    }

    .headline-s {
        @include headline-s;
    }

    .title-l {
        @include title-l;
    }

    .title-m {
        @include title-m;
    }

    .title-s {
        @include title-s;
    }

    .body-m-1 {
        @include body-m-1;
    }

    .body-m-2 {
        @include body-m-2;
    }

    .body-s {
        @include body-s;
    }

    .body-s-1 {
        @include body-s;
    }

    .body-s-2 {
        @include body-s-2;
    }

    .label-l {
        @include label-l;
    }

    .label-s {
        @include label-s;
    }

    input {
        @include input-text;
    }

    .body-1 {
        @include body-m-1;
    }

    .body-2 {
        @include body-m-2;
    }
}

:root {
    --mdc-typography-body1-font-size: 14px;
    --mdc-typography-body1-line-height: 20px;
    --mdc-typography-body1-letter-spacing: 0;
    --mdc-typography-body1-font-weight: 400;
    --mdc-typography-body1-text-decoration: none;
    --mdc-typography-body1-text-transform: none;

    --mdc-typography-body2-font-size: 14px;
    --mdc-typography-body2-line-height: 20px;
    --mdc-typography-body2-letter-spacing: 0;
    --mdc-typography-body2-font-weight: 600;
    --mdc-typography-body2-text-decoration: none;
    --mdc-typography-body2-text-transform: none;

    --mdc-typography-caption-letter-spacing: normal;
    --mdc-typography-caption-text-transform: none;

    --mdc-protected-button-label-text-tracking: 0;
}
