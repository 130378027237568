.vc-grid-layout {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;

    &.fixed {
        width: 1060px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 1920px) {
    .vc-grid-layout.fixed {
        max-width: 1600px;
    }
}

.vc-grid-layout > .col,
.vc-grid-layout > [class*='col'] {
    box-sizing: border-box;
}

$breakpoints: (
    'lg': 1366px,
    'xl': 1920px,
) !default;

$grid-columns: (
    'col-1': 8.3333%,
    'col-2': 16.6667%,
    'col-3': 25%,
    'col-4': 33.3333%,
    'col-5': 41.6667%,
    'col-6': 50%,
    'col-7': 58.3333%,
    'col-8': 66.6667%,
    'col-9': 75%,
    'col-10': 83.3333%,
    'col-11': 91.6667%,
    'col-12': 100%,
) !default;

@each $col, $width in $grid-columns {
    .#{$col} {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: #{$width};
        box-sizing: border-box;
    }
}

@each $key, $val in $breakpoints {
    @media screen and (min-width: #{$val}) {
        .#{$key + '\\:'} {
            &col {
                flex-grow: 1;
                flex-basis: 0;
                padding: 0.5rem;
            }

            &col-fixed {
                flex: 0 0 auto;
                padding: 0.5rem;
            }

            @each $col, $width in $grid-columns {
                &#{$col} {
                    flex: 0 0 auto;
                    padding: 0.5rem;
                    width: #{$width};
                    box-sizing: border-box;
                }
            }
        }
    }
}
