:root[data-theme='theme-light'] {
    --input-text: var(--color-21);
    --input-border: var(--color-d0);
    --input-hovered-border: var(--color-d0);
    --textarea-focus-border: var(--color-d0);

    --pager-bg: var(--color-f5);
    --pager-number-text: var(--color-21);
    --pager-text: var(--color-00-b3);
    --pager-number-hover-text: var(--color-21);
    --pager-number-hover-bg: var(--color-00-0a);
    --pager-number-focus-bg: var(--color-00-0a);
    --pager-border-width: 0 1px 1px;

    --grid-sticky-hovered-bg: var(--color-f5);

    --panelbar-header-text: var(--color-21);
    --panelbar-header-selected-text: var(--color-21);
    --panelbar-text: var(--color-70);

    --card-text: var(--color-21);

    --calendar-text: var(--color-21);
    --calendar-bg: var(--color-dc);
    --calendar-border: var(--color-cb);
    --calendar-navigation-text: var(--color-21);
    --calendar-navigation-bg: var(--color-dc);
    --calendar-navigation-border: var(--color-cb);
    --calendar-cell-hover-text: var(--color-21);
    --calendar-cell-hover-bg: var(--color-dc);
    --calendar-header-cell-text: var(--color-00-61);

    --hovered-text: var(--color-21);
    --hovered-bg: var(--color-ed);
    --hovered-border: var(--color-de);

    --tooltip-text: var(--color-21);
    --tooltip-bg: var(--color-ed);
    --tooltip-border: var(--color-ed);

    --progressbar-text: var(--color-21);

    --vc-white-logo-display: none;
    --vc-dark-logo-display: block;

    --vc-topnavigation-txt: var(--color-21);

    --vc-link-txt: var(--color-59);

    --vc-form-clr: var(--color-21);

    --vc-editor-dialog-content-bg: var(--color-ff);
    --vc-editor-dialog-buttongroup-bg: var(--color-ff);
    --vc-editor-button-bg: var(--color-dc);

    --vc-header-bg: var(--color-dc);
    --vc-header-bottom-bdr: 0px;
    --vc-header-box-shadow: 0px 0px 5px 2px;
    --vc-header-box-shadow-clr: var(--color-00-33);

    --vc-search-header-bg: var(--color-ff);

    --vc-custom-scrollbar-track-bg: var(--color-d0);
    --vc-webkit-scrollbar-track-bg: var(--color-d0);
    --vc-webkit-scrollbar-thumb-bg: var(--color-e5);

    --vc-area-disabled-bg: var(--color-ff-e5);

    --vc-grid-table-tbody-bg: var(--color-ff);
    --vc-ttl-text-clr: var(--color-70);
    --vc-desc-text-clr: var(--color-21);
    --vc-breadcrumb-box: var(--color-ff);

    --vc-progress-bar-bg: var(--color-ed);

    --vc-recent-box-clr: var(--color-3e);
    --vc-grid-table-brdr: var(--color-00-1f);

    --dropdown-multiselect-bg: var(--color-ff);
    --dropdown-multiselect-border: var(--color-dde);
    --dropdown-multiselect-text-color: var(--color-23);
    --dropdown-multiselect-chip-bg: var(--color-f5);
    --dropdown-multiselect-chip-text: var(--color-71);
    --dropdown-multiselect-checkbox-checked: var(--color-3f);

    --vc-font-gray-clr: var(--color-42);
    --vc-tabstrip-items-active-bg: var(--color-ff);
    --vc-tabstrip-items-bg: var(--color-dc);
    --vc-tabstrip-items-clr: var(--color-21);
    --vc-ttl-card-header-bg: var(--color-ff);
    --vc-grid-header-bg: var(--color-f5);
    --vc-grid-header-clr: var(--color-21);
    --vc-grid-header-sort: var(--color-00-b3);

    --vc-form-merchant-box-content-bg: var(--color-ff);
    --vc-chip-filled-bg: var(--color-ed);
    --vc-chip-filled-clr: var(--color-21);

    --vc-grid-txt: var(--color-21);
    --vc-grid-tr-bg: var(--color-f5);

    --vc-grid-bdr: var(--color-d0);
    --vc-grid-clr: var(--color-21);
    --vc-grid-bg-clr: transparent;

    --vc-chart-surface-bg: var(--color-ff);

    --vc-multiselect-wrap-btn-hvr: var(--color-00-1a);
    --vc-checkbox-hvr-bc: var(--color-42);

    --vc-grid-state-selected-clr: var(--color-00);
    --vc-grid-state-selected-bg: var(--color-dc);

    --vc-loader-container-bg: var(--color-ff-cf);
    --vc-widget-header-bg: linear-gradient(90deg, var(--color-db) 0%, var(--color-f4) 100%);

    --vc-list-point-bt: var(--color-d5);
    --vc-list-point-li-clr: var(--color-21);
    --vc-ttl-point-bb: var(--color-d5);

    --vc-value-point-bg: var(--color-ed);
    --vc-value-point-clr: var(--color-21);

    --vc-sep-line-bb: var(--color-d5);
    --vc-progressbar-state-selected-bg: var(--color-59);
    --vc-round-icon-black-bg: var(--color-d0);
    --vc-status-info-bg: var(--color-ed);

    --vc-list-items-bt: var(--color-d5);
    --vc-list-items-li-clr: var(--color-21);
    --vc-list-items-li-bb: var(--color-d5);

    --vc-check-box-org-bg: var(--color-dc);
    --vc-check-box-org-checkbox-fcs-clr: var(--color-70);
    --vc-check-box-org-checkbox-fcs-bc: var(--color-70);

    --vc-textbox-container-label-clr: var(--color-9c);
    --vc-textbox-container-label-bg: var(--color-ff);

    --vc-tags-bg: var(--color-dc);
    --vc-tags-clr: var(--color-21);
    --vc-tags-bc: var(--color-dc);

    --vc-count-box-fw-bg: var(--color-ed);
    --vc-count-box-fw-clr: var(--color-21);

    --vc-advance-search-bg: var(--color-ed);
    --vc-advance-search-switch-clr: var(--color-4d);

    --vc-var-base-bg-clr: var(--color-ff);
    --vc-var-background-clr: var(--color-ff);
    --vc-var-normal-bg-clr: var(--color-ff);
    --vc-var-normal-txt-clr: var(--color-00-de);
    --vc-var-hover-bg-clr: var(--color-00-0a);
    --vc-var-hover-txt-clr: var(--color-00-de);
    --vc-var-gauge-track-bg-clr: var(--color-e6);
    --vc-var-chart-inactive-bg-clr: var(--color-00-80);

    --vc-webkit-autofill-bg: var(--color-ff);
    --vc-webkit-text-fill-color: var(--color-21);

    --vc-action-bg: var(--color-cf-00);
    --vc-action-linear-gradient-bg: var(--color-cf-f2);
    --vc-action-webkit-gradient-bg: var(--color-cf);

    --vc-chart-box-form-bg: var(--color-ff);
    --vc-box-auto-contain-bg: var(--color-ff);
    --vc-tabstrip-chart-box-bg: var(--color-ff);

    --vc-tooltip-text-bg: var(--color-dc);
    --vc-tooltip-text-clr: var(--color-21);
    --vc-gray-tag-clr: var(--color-21);

    --vc-calendar-today-bg: var(--color-cb);

    --vc-ngx-json-viewer-clr: var(--color-70);

    --vc-chart-box-content-textarea-bg: transparent;

    --vc-radio-hovered-br: var(--color-42);
    --vc-file-hvr-bg: var(--color-f5);

    --vc-bar-chart-text-fill: var(--color-21);

    --vc-chip-remove-icon-clr: var(--color-30);


    --vc-checkbox-ckd-br-clr: var(--color-3f);

    --vc-date-input-placeholder-clr: var(--color-00);

    --vc-grid-header-link-hvr-clr: var(--color-21);

    --vc-trigger-dlg-bg: var(--color-dc); //--color-ff
    --vc-second-factor-dlg-bg: var(--color-ed);

    --vc-upload-widget-br-clr: var(--color-d0);
    --vc-reset-btn-clr: var(--color-76);

    --vc-upload-dropzone-clr: var(--color-21);
    --vc-upload-dropzone-bg-clr: var(--color-ff);
    --vc-upload-dropzone-hnt-clr: var(--color-21);

    --vc-upload-file-bg-clr: var(--color-dc);
    --vc-upload-file-clr: var(--color-70);

    --vc-form-text-title-clr: var(--color-21);
    --vc-grid-common-br-clr: var(--color-00-1f);

    --vc-popup-bg-clr: var(--color-ed);
    --vc-popup-clr: var(--color-21);
    --vc-popup-a-clr: var(--color-21);

    --vc-toogle-btn-clr: var(--color-21);
    --vc-time-highlight-bg-clr: var(--color-9c);

    --vc-filter-menu-actn-btn-bg-clr: var(--color-dc);
    --vc-filter-menu-btn-primary-disabled-clr: var(--color-70);

    --vc-filter-multiselect-wrap-btn-bg-clr: var(--color-dc);
    --vc-filter-multiselect-wrap-btn-clr: var(--color-70);
    --vc-filter-multiselect-wrap-btn-hvr: var(--color-00-1a);
    --vc-datetime-time-wrap-bg-clr: var(--color-e3);
    --vc-datetime-container-tab-bg-clr: var(--color-ed);

    --vc-checkbox-lbl-clr: var(--color-00-4d);

    --vc-clr-default-clr: var(--color-21);

    --vc-notification-pref-hovered-text: var(--color-21);
    --vc-notification-pref-hovered-bg: var(--color-cb);

    --app-loader-logo: url('/assets/images/logo/vc-logo-vikingcloud.svg');
    --app-loader-text: #0039b3;
}
