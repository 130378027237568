.vc-table-wrapper {
    --mat-paginator-container-text-tracking: normal;

    .vc-table-actions-right-side {
        button:not(:disabled) {
            color: var(--primary-700);
        }
    }
    .vc-table-action-buttons {
        button:not(:disabled) {
            color: var(--secondary-800);
        }
    }

    .vc-table-container {
        table {
            .vc-table-column-header {
                .mat-sort-header-arrow {
                    color: var(--text-high-emphasis) !important;
                }

                .mat-sort-header-content {
                    overflow: hidden;
                    max-width: 90%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    text-align: left;
                }
            }
        }
    }

    .mat-mdc-no-data-row {
        height: 48px;
    }

    .mat-mdc-paginator-container {
        color: var(--text-low-emphasis);
        justify-content: space-between !important;
    }
}

.vc-resizer {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    cursor: grab;
    width: 24px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    background-color: var(--background);

    &::before {
        content: url('/assets/icons/column-resizer-icon.svg');
        height: 24px;
        width: 24px;
    }

    &:hover,
    &:active {
        opacity: 1;
    }
}

.vc-table-toolbar {
    vc-input {
        height: 40px;
        width: 100%;

        .mat-mdc-form-field {
            .mat-mdc-text-field-wrapper {
                height: 40px;

                .mat-mdc-form-field-flex {
                    height: 40px;

                    .mat-mdc-form-field-infix {
                        padding-top: 16px;

                        .mat-mdc-floating-label {
                            top: 20px;
                        }
                    }
                }
            }
        }
    }
}
