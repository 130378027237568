highcharts-chart {
    &:not(.no-title) {
        .highcharts-title {
            left: 0 !important;
            top: 0 !important;
            border-bottom: 2px solid var(--outline);
            padding: 16px 24px !important;
            width: 100% !important;
            align-content: center;
            box-sizing: border-box;
        }
    }

    .highcharts-no-data {
        margin-top: 18px;
        left: 2px !important;
        right: 2px !important;
        bottom: 2px !important;

        &>span {
            left: 16px !important;
            right: 16px !important;
            bottom: 2px !important;
            color: var(--text-high-emphasis) !important;
            font-weight: 400 !important;
            align-content: center;

            .vc-chart-empty-icon {
                color: var(--text-placeholder) !important;
            }
        }
    }
}
