@use '@angular/material' as mat;
@use 'typography' as typography;

$color-primary-500: #0055ff;
$color-primary-50: #001e60;
$color-primary-100: #003099;
$color-primary-200: #0039b3;
$color-primary-300: #0043ce;
$color-primary-400: #004dea;
$color-primary-600: #3a6aff;
$color-primary-700: #68a4ff;
$color-primary-800: #90caff;
$color-primary-900: #b6d8ff;

$color-secondary-800: #aec6ff;
$color-secondary-900: #d9e3ff;
$color-secondary-700: #85aafc;
$color-secondary-600: #6a8fdf;
$color-secondary-500: #4f75c3;
$color-secondary-400: #345ca8;
$color-secondary-300: #14448f;
$color-secondary-200: #003881;
$color-secondary-100: #002e6b;
$color-secondary-50: #001a43;

$vc-primary-palette: (
    50: $color-primary-50,
    100: $color-primary-100,
    200: $color-primary-200,
    300: $color-primary-300,
    400: $color-primary-400,
    500: $color-primary-500,
    600: $color-primary-600,
    700: $color-primary-700,
    800: $color-primary-800,
    900: $color-primary-900,
    contrast: (
        50: #ffffffb3,
        100: #ffffffb3,
        200: #ffffffb3,
        300: #ffffffb3,
        400: #ffffffb3,
        500: #ffffffb3,
        600: #ffffffb3,
        700: #ffffffb3,
        800: #ffffffb3,
        900: #ffffffb3,
    ),
);

$vc-accent-palette: (
    50: $color-secondary-50,
    100: $color-secondary-100,
    200: $color-secondary-200,
    300: $color-secondary-300,
    400: $color-secondary-400,
    500: $color-secondary-500,
    600: $color-secondary-600,
    700: $color-secondary-700,
    800: $color-secondary-800,
    900: $color-secondary-900,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffffb3,
        400: #ffffffb3,
        500: #ffffffb3,
        600: #ffffffb3,
        700: #ffffffb3,
        800: #ffffffb3,
        900: #ffffffb3,
    ),
);

$vc-warn-palette: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #ff616e,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffffb3,
        400: #ffffffb3,
        500: #ffffffb3,
        600: #ffffffb3,
        700: #ffffffb3,
        800: #ffffffb3,
        900: #ffffffb3,
    ),
);

$vc-primary: mat.m2-define-palette($vc-primary-palette, 500, 800, 200);
$vc-accent: mat.m2-define-palette($vc-accent-palette, 800, 500, 600);
$vc-warn: mat.m2-define-palette($vc-warn-palette);
$vc-typography: mat.m2-define-typography-config(
    $font-family: typography.$font-family-poppins,
);
$vc-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $vc-primary,
            accent: $vc-accent,
            warn: $vc-warn,
        ),
        typography: $vc-typography,
    )
);

:root[data-theme='theme-dark'] {
    //Palette Colors
    --primary-500: #0055ff;
    --primary-50: #001e60;
    --primary-100: #003099;
    --primary-200: #0039b3;
    --primary-300: #0043ce;
    --primary-400: #004dea;
    --primary-600: #3a6aff;
    --primary-700: #68a4ff;
    --primary-800: #90caff;
    --primary-900: #b6d8ff;

    --secondary-900: #d9e3ff;
    --secondary-800: #aec6ff;
    --secondary-700: #85aafc;
    --secondary-600: #6a8fdf;
    --secondary-500: #4f75c3;
    --secondary-400: #345ca8;
    --secondary-300: #14448f;
    --secondary-200: #003881;
    --secondary-100: #002e6b;
    --secondary-50: #001a43;

    // General colors
    --shadow-color: rgba(35, 42, 66, 0.15);
    --background: #01020b;
    --surface: #1b2033;
    --outline: #283757;
    --ghost-white: #f6f8fb;
    --text-high-emphasis: #f6f8fb;
    --text-medium-emphasis: #cacbd0;
    --text-low-emphasis: #9da0ac;
    --text-placeholder: #727680;

    // Notification colors
    --warning-color: #f8cf33;
    --warning-background: #8d6a09;
    --warning-text-color: #fef8e0;

    --error-color: #ff4754;
    --error-background: #400001;
    --error-text-color: #ff616e;

    --success-color: #23a58e;
    --success-background: #176b5c;
    --success-text-color: #f4fbf9;

    --info-color: #90caff;
    --info-background: #001e60;
    --info-text-color: #90caff;
}
