:root {
  --color-00: #000;
  --color-ff: #fff;
  --color-76: #767676;
  --color-30: #303030;
  --color-42: #424242;
  --color-4a: #4a4a4a;
  --color-dc: #dce1ef;
  --color-be: #bebebe;
  --color-70: #707696;
  --color-21: #212a44;
  --color-d0: #d0d4e1;
  --color-42-e5: #424242e5;
  --color-ff-e5: #ffffffe5;
  --color-eb: #ebebeb;
  --color-e1: #e1e3e9;
  --color-ec: #eceef8;
  --color-ed: #edeff5;
  --color-61: #616161;
  --color-d5: #d5d5d5;
  --color-2d: #2d2d2d;
  --color-2e: #2e2e2e;
  --color-59: #599ada;
  --color-3e: #3e4777;
  --color-4d: #4d4d4d;
  --color-9c: #9c9c9c;
  --color-53: #535353;
  --color-40: #4054b2;
  --color-44: #448aff;
  --color-49: #4b9cdb;
  --color-f5: #f5f6fa;
  --color-b7: #b7c1dd;
  --color-07: #0074c9;
  --color-cb: #cbcbcb;
  --color-e5: #e5e8ef;
  --color-37: #37B400;
  --color-00-de: #000000de;
  --color-00-0a: #0000000a;
  --color-00-80: #00000080;
  --color-ff-14: #ffffff14;
  --color-ff-80: #ffffff80;
  --color-e6: #e6e6e6;
  --color-51: #515151;
  --color-de: #dedede;
  --color-ff-1f: #ffffff1f;
  --color-13: #131313;
  --color-13-00: #13131300;
  --color-13-f2: #131313f2;
  --color-cf-00: #cfcfcf00;
  --color-cf-f2: #cfcfcff2;
  --color-cf: #cfcfcf;
  --color-00-33: #00000033;
  --color-00-14: #00000014;
  --color-00-61: #00000061;
  --color-ff-61: #ffffff61;
  --color-50: #505050;
  --color-ff-0a: #ffffff0a;
  --color-ff-26: #ffffff26;
  --color-48: #484848;
  --color-00-4d: #0000004d;
  --color-4b: #4b4b4b;
  --color-000: #000000;
  --color-ff-b3: #ffffffb3;
  --color-ff-38: #ffffff38;
  --color-ff-40: #ffffff40;
  --color-00-1a: #0000001a;
  --color-db: #dbe0ed;
  --color-f4: #f4f4f4;
  --color-b0: #b0b6c7;
  --color-ff-cf: #ffffffcf;
  --color-00-1f: #0000001f;
  --color-00-b3: #000000b3;
  --color-00-85: #00000085;
  --color-00-cf: #000000cf;
  --color-3f: #3f51b5;
  --color-e3: #e3e7f1;
  --color-f5-f5: #f5f5f5;
  --color-b2: #b2b7c6;
  --color-23: #232A42;
  --color-52: #5262B2;
  --color-71: #717693;
  --color-d1: #d1d4e0;
  --color-dde: #dde1ee;
  --color-fa: #fafbfc;
  --color-d5d: #d5d9e7;
  --color-d5d-99: #d5d9e799;
  --color-ea: #EA2E24;
  --color-d9: #d9534f;
  --color-fe:#fef4f4;
  --color-ff0:#ff0000;
  --color-ea-33: #EA2E2433;
  --color-fa9-33: #FA9F4733;
  --color-ff7: #FF7D00;
  --color-ff7-cc: #ff7d00cc;
  --color-3f-e5: #3f51b5e5;
  --color-68: #6899d5;
  --color-00-a6:#00a650;
  --color-d6: #D60B00;
  --color-e9: #E9EBF7;
  --color-ffe: #FFECE6;
  --color-f4f: #F4FBF9;
  --color-17: #176B5C;
  --color-8d: #8D6A09;
  --color-fef: #FEF8E0;
}

:root[data-theme=theme-dark] {
  --input-text: var(--color-ff);
  --input-border: var(--color-ff-40);
  --input-hovered-border: var(--color-ff-40);
  --textarea-focus-border: var(--color-ff-40);
  --pager-bg: var(--color-42);
  --pager-number-text: var(--color-ff);
  --pager-text: var(--color-ff-b3);
  --pager-number-hover-text: var(--color-ff);
  --pager-number-hover-bg: var(--color-ff-0a);
  --pager-number-focus-bg: var(--color-42);
  --pager-border-width: 1px 0 0;
  --grid-sticky-hovered-bg: var(--color-42);
  --panelbar-header-text: var(--color-ff);
  --panelbar-header-selected-text: var(--color-ff);
  --panelbar-text: var(--color-ff);
  --card-text: var(--color-ff);
  --calendar-text: var(--color-ff);
  --calendar-bg: var(--color-42);
  --calendar-border: var(--color-ff-1f);
  --calendar-navigation-text: var(--color-ff);
  --calendar-navigation-bg: var(--color-42);
  --calendar-navigation-border: var(--color-ff-1f);
  --calendar-cell-hover-text: var(--color-ff);
  --calendar-cell-hover-bg: var(--color-42);
  --calendar-header-cell-text: var(--color-ff-61);
  --hovered-text: var(--color-ff);
  --hovered-bg: var(--color-ff-0a);
  --hovered-border: var(--color-ff-26);
  --tooltip-text: var(--color-ff);
  --tooltip-bg: var(--color-61);
  --tooltip-border: var(--color-61);
  --progressbar-text: var(--color-ff);
  --vc-white-logo-display: block;
  --vc-dark-logo-display: none;
  --vc-topnavigation-txt: var(--color-ff);
  --vc-link-txt: var(--color-ff);
  --vc-form-clr: var(--color-ff);
  --vc-editor-dialog-content-bg: var(--color-30);
  --vc-editor-dialog-buttongroup-bg: var(--color-30);
  --vc-editor-button-bg: var(--color-42);
  --vc-header-bg: var(--color-00);
  --vc-header-bottom-bdr: 1px solid var(--color-30);
  --vc-header-box-shadow: 2px 13px 11px -10px;
  --vc-header-box-shadow-clr: var(--color-00-85);
  --vc-search-header-bg: var(--color-30);
  --vc-custom-scrollbar-track-bg: var(--color-30);
  --vc-webkit-scrollbar-track-bg: var(--color-00);
  --vc-webkit-scrollbar-thumb-bg: var(--color-53);
  --vc-area-disabled-bg: var(--color-42-e5);
  --vc-grid-table-tbody-bg: var(--color-30);
  --vc-ttl-text-clr: var(--color-be);
  --vc-desc-text-clr: var(--color-ff);
  --vc-breadcrumb-box: var(--color-42);
  --vc-progress-bar-bg: var(--color-ff);
  --vc-recent-box-clr: var(--color-59);
  --vc-grid-table-brdr: var(--color-ff-1f);
  --dropdown-multiselect-bg: var(--color-42);
  --dropdown-multiselect-border: var(--color-50);
  --dropdown-multiselect-text-color: var(--color-ff);
  --dropdown-multiselect-chip-bg: var(--color-50);
  --dropdown-multiselect-chip-text: var(--color-ff);
  --dropdown-multiselect-checkbox-checked: var(--color-3f);
  --vc-font-gray-clr: var(--color-be);
  --vc-tabstrip-items-active-bg: var(--color-30);
  --vc-tabstrip-items-bg: var(--color-42);
  --vc-tabstrip-items-clr: var(--color-ff);
  --vc-ttl-card-header-bg: var(--color-42);
  --vc-grid-header-bg: var(--color-42);
  --vc-grid-header-clr: var(--color-ff-b3);
  --vc-grid-header-sort: var(--color-ff-b3);
  --vc-form-merchant-box-content-bg: var(--color-30);
  --vc-chip-filled-bg: var(--color-30);
  --vc-chip-filled-clr: var(--color-ff);
  --vc-grid-txt: var(--color-ff);
  --vc-grid-tr-bg: var(--color-ff-14);
  --vc-grid-bdr: var(--color-ff-1f);
  --vc-grid-clr: var(--color-ff);
  --vc-grid-bg-clr: var(--color-42);
  --vc-chart-surface-bg: var(--color-42);
  --vc-multiselect-wrap-btn-hvr: var(--color-9c);
  --vc-checkbox-hvr-bc: var(--color-ff);
  --vc-grid-state-selected-clr: var(--color-ff);
  --vc-grid-state-selected-bg: var(--color-53);
  --vc-loader-container-bg: var(--color-00-cf);
  --vc-widget-header-bg: linear-gradient(90deg, var(--color-4b) 0%, var(--color-000) 100%);
  --vc-list-point-bt: var(--color-61);
  --vc-list-point-li-clr: var(--color-d5);
  --vc-ttl-point-bb: var(--color-61);
  --vc-value-point-bg: var(--color-2d);
  --vc-value-point-clr: var(--color-ff);
  --vc-sep-line-bb: var(--color-ff-1f);
  --vc-progressbar-state-selected-bg: var(--color-07);
  --vc-round-icon-black-bg: var(--color-00);
  --vc-status-info-bg: var(--color-30);
  --vc-list-items-bt: var(--color-61);
  --vc-list-items-li-clr: var(--color-d5);
  --vc-list-items-li-bb: var(--color-61);
  --vc-check-box-org-bg: var(--color-42);
  --vc-check-box-org-checkbox-fcs-clr: var(--color-ff);
  --vc-check-box-org-checkbox-fcs-bc: var(--color-ff);
  --vc-textbox-container-label-clr: var(--color-cb);
  --vc-textbox-container-label-bg: var(--color-30);
  --vc-tags-bg: var(--color-70);
  --vc-tags-clr: var(--color-00);
  --vc-tags-bc: var(--color-3e);
  --vc-count-box-fw-bg: var(--color-42);
  --vc-count-box-fw-clr: var(--color-ff);
  --vc-advance-search-bg: var(--color-42);
  --vc-advance-search-switch-clr: var(--color-cb);
  --vc-var-base-bg-clr: var(--color-42);
  --vc-var-background-clr: var(--color-42);
  --vc-var-normal-bg-clr: var(--color-42);
  --vc-var-normal-txt-clr: var(--color-ff);
  --vc-var-hover-bg-clr: var(--color-ff-14);
  --vc-var-hover-txt-clr: var(--color-ff);
  --vc-var-gauge-track-bg-clr: var(--color-51);
  --vc-var-chart-inactive-bg-clr: var(--color-ff-80);
  --vc-webkit-autofill-bg: var(--color-30);
  --vc-webkit-text-fill-color: var(--color-ff);
  --vc-action-bg: var(--color-13-00);
  --vc-action-linear-gradient-bg: var(--color-13-f2);
  --vc-action-webkit-gradient-bg: var(--color-13);
  --vc-chart-box-form-bg: var(--color-30);
  --vc-box-auto-contain-bg: var(--color-30);
  --vc-tabstrip-chart-box-bg: var(--color-30);
  --vc-tooltip-text-bg: var(--color-70);
  --vc-tooltip-text-clr: var(--color-00);
  --vc-gray-tag-clr: var(--color-00);
  --vc-calendar-today-bg: var(--color-50);
  --vc-ngx-json-viewer-clr: var(--color-be);
  --vc-chart-box-content-textarea-bg: var(--color-30);
  --vc-radio-hovered-br: var(--color-ff);
  --vc-file-hvr-bg: var(--color-ff-14);
  --vc-bar-chart-text-fill: var(--color-ff);
  --vc-chip-remove-icon-clr: var(--color-ff-b3);
  --vc-checkbox-ckd-br-clr: var(--color-ff);
  --vc-date-input-placeholder-clr: var(--color-ff);
  --vc-grid-header-link-hvr-clr: var(--color-ff);
  --vc-trigger-dlg-bg: var(--color-42);
  --vc-second-factor-dlg-bg: var(--color-42);
  --vc-upload-widget-br-clr: var(--color-ff-1f);
  --vc-reset-btn-clr: var(--color-ff);
  --vc-upload-dropzone-clr: var(--color-ff);
  --vc-upload-dropzone-bg-clr: var(--color-42);
  --vc-upload-dropzone-hnt-clr: var(--color-ff-b3);
  --vc-upload-file-bg-clr: var(--color-42);
  --vc-upload-file-clr: var(--color-ff);
  --vc-form-text-title-clr: var(--color-ff);
  --vc-grid-common-br-clr: var(--color-ff-1f);
  --vc-popup-bg-clr: var(--color-42);
  --vc-popup-clr: var(--color-ff);
  --vc-popup-a-clr: var(--color-ff);
  --vc-toogle-btn-clr: var(--color-ff);
  --vc-time-highlight-bg-clr: var(--color-ff-1f);
  --vc-filter-menu-actn-btn-bg-clr: var(--color-51);
  --vc-filter-menu-btn-primary-disabled-clr: var(--color-00-61);
  --vc-filter-multiselect-wrap-btn-bg-clr: var(--color-4d);
  --vc-filter-multiselect-wrap-btn-clr: var(--color-ff);
  --vc-filter-multiselect-wrap-btn-hvr: var(--color-9c);
  --vc-datetime-time-wrap-bg-clr: var(--color-42);
  --vc-datetime-container-tab-bg-clr: var(--color-42);
  --vc-checkbox-lbl-clr: var(--color-ff-b3);
  --vc-clr-default-clr: var(--color-ff);
  --vc-notification-pref-hovered-text: var(--color-ff);
  --vc-notification-pref-hovered-bg: var(--color-ff-0a);
  --app-loader-logo: url("/assets/images/logo/vc-logo-vikingcloud-dark.svg");
  --app-loader-text: #68a4ff;
}

:root[data-theme=theme-light] {
  --input-text: var(--color-21);
  --input-border: var(--color-d0);
  --input-hovered-border: var(--color-d0);
  --textarea-focus-border: var(--color-d0);
  --pager-bg: var(--color-f5);
  --pager-number-text: var(--color-21);
  --pager-text: var(--color-00-b3);
  --pager-number-hover-text: var(--color-21);
  --pager-number-hover-bg: var(--color-00-0a);
  --pager-number-focus-bg: var(--color-00-0a);
  --pager-border-width: 0 1px 1px;
  --grid-sticky-hovered-bg: var(--color-f5);
  --panelbar-header-text: var(--color-21);
  --panelbar-header-selected-text: var(--color-21);
  --panelbar-text: var(--color-70);
  --card-text: var(--color-21);
  --calendar-text: var(--color-21);
  --calendar-bg: var(--color-dc);
  --calendar-border: var(--color-cb);
  --calendar-navigation-text: var(--color-21);
  --calendar-navigation-bg: var(--color-dc);
  --calendar-navigation-border: var(--color-cb);
  --calendar-cell-hover-text: var(--color-21);
  --calendar-cell-hover-bg: var(--color-dc);
  --calendar-header-cell-text: var(--color-00-61);
  --hovered-text: var(--color-21);
  --hovered-bg: var(--color-ed);
  --hovered-border: var(--color-de);
  --tooltip-text: var(--color-21);
  --tooltip-bg: var(--color-ed);
  --tooltip-border: var(--color-ed);
  --progressbar-text: var(--color-21);
  --vc-white-logo-display: none;
  --vc-dark-logo-display: block;
  --vc-topnavigation-txt: var(--color-21);
  --vc-link-txt: var(--color-59);
  --vc-form-clr: var(--color-21);
  --vc-editor-dialog-content-bg: var(--color-ff);
  --vc-editor-dialog-buttongroup-bg: var(--color-ff);
  --vc-editor-button-bg: var(--color-dc);
  --vc-header-bg: var(--color-dc);
  --vc-header-bottom-bdr: 0px;
  --vc-header-box-shadow: 0px 0px 5px 2px;
  --vc-header-box-shadow-clr: var(--color-00-33);
  --vc-search-header-bg: var(--color-ff);
  --vc-custom-scrollbar-track-bg: var(--color-d0);
  --vc-webkit-scrollbar-track-bg: var(--color-d0);
  --vc-webkit-scrollbar-thumb-bg: var(--color-e5);
  --vc-area-disabled-bg: var(--color-ff-e5);
  --vc-grid-table-tbody-bg: var(--color-ff);
  --vc-ttl-text-clr: var(--color-70);
  --vc-desc-text-clr: var(--color-21);
  --vc-breadcrumb-box: var(--color-ff);
  --vc-progress-bar-bg: var(--color-ed);
  --vc-recent-box-clr: var(--color-3e);
  --vc-grid-table-brdr: var(--color-00-1f);
  --dropdown-multiselect-bg: var(--color-ff);
  --dropdown-multiselect-border: var(--color-dde);
  --dropdown-multiselect-text-color: var(--color-23);
  --dropdown-multiselect-chip-bg: var(--color-f5);
  --dropdown-multiselect-chip-text: var(--color-71);
  --dropdown-multiselect-checkbox-checked: var(--color-3f);
  --vc-font-gray-clr: var(--color-42);
  --vc-tabstrip-items-active-bg: var(--color-ff);
  --vc-tabstrip-items-bg: var(--color-dc);
  --vc-tabstrip-items-clr: var(--color-21);
  --vc-ttl-card-header-bg: var(--color-ff);
  --vc-grid-header-bg: var(--color-f5);
  --vc-grid-header-clr: var(--color-21);
  --vc-grid-header-sort: var(--color-00-b3);
  --vc-form-merchant-box-content-bg: var(--color-ff);
  --vc-chip-filled-bg: var(--color-ed);
  --vc-chip-filled-clr: var(--color-21);
  --vc-grid-txt: var(--color-21);
  --vc-grid-tr-bg: var(--color-f5);
  --vc-grid-bdr: var(--color-d0);
  --vc-grid-clr: var(--color-21);
  --vc-grid-bg-clr: transparent;
  --vc-chart-surface-bg: var(--color-ff);
  --vc-multiselect-wrap-btn-hvr: var(--color-00-1a);
  --vc-checkbox-hvr-bc: var(--color-42);
  --vc-grid-state-selected-clr: var(--color-00);
  --vc-grid-state-selected-bg: var(--color-dc);
  --vc-loader-container-bg: var(--color-ff-cf);
  --vc-widget-header-bg: linear-gradient(90deg, var(--color-db) 0%, var(--color-f4) 100%);
  --vc-list-point-bt: var(--color-d5);
  --vc-list-point-li-clr: var(--color-21);
  --vc-ttl-point-bb: var(--color-d5);
  --vc-value-point-bg: var(--color-ed);
  --vc-value-point-clr: var(--color-21);
  --vc-sep-line-bb: var(--color-d5);
  --vc-progressbar-state-selected-bg: var(--color-59);
  --vc-round-icon-black-bg: var(--color-d0);
  --vc-status-info-bg: var(--color-ed);
  --vc-list-items-bt: var(--color-d5);
  --vc-list-items-li-clr: var(--color-21);
  --vc-list-items-li-bb: var(--color-d5);
  --vc-check-box-org-bg: var(--color-dc);
  --vc-check-box-org-checkbox-fcs-clr: var(--color-70);
  --vc-check-box-org-checkbox-fcs-bc: var(--color-70);
  --vc-textbox-container-label-clr: var(--color-9c);
  --vc-textbox-container-label-bg: var(--color-ff);
  --vc-tags-bg: var(--color-dc);
  --vc-tags-clr: var(--color-21);
  --vc-tags-bc: var(--color-dc);
  --vc-count-box-fw-bg: var(--color-ed);
  --vc-count-box-fw-clr: var(--color-21);
  --vc-advance-search-bg: var(--color-ed);
  --vc-advance-search-switch-clr: var(--color-4d);
  --vc-var-base-bg-clr: var(--color-ff);
  --vc-var-background-clr: var(--color-ff);
  --vc-var-normal-bg-clr: var(--color-ff);
  --vc-var-normal-txt-clr: var(--color-00-de);
  --vc-var-hover-bg-clr: var(--color-00-0a);
  --vc-var-hover-txt-clr: var(--color-00-de);
  --vc-var-gauge-track-bg-clr: var(--color-e6);
  --vc-var-chart-inactive-bg-clr: var(--color-00-80);
  --vc-webkit-autofill-bg: var(--color-ff);
  --vc-webkit-text-fill-color: var(--color-21);
  --vc-action-bg: var(--color-cf-00);
  --vc-action-linear-gradient-bg: var(--color-cf-f2);
  --vc-action-webkit-gradient-bg: var(--color-cf);
  --vc-chart-box-form-bg: var(--color-ff);
  --vc-box-auto-contain-bg: var(--color-ff);
  --vc-tabstrip-chart-box-bg: var(--color-ff);
  --vc-tooltip-text-bg: var(--color-dc);
  --vc-tooltip-text-clr: var(--color-21);
  --vc-gray-tag-clr: var(--color-21);
  --vc-calendar-today-bg: var(--color-cb);
  --vc-ngx-json-viewer-clr: var(--color-70);
  --vc-chart-box-content-textarea-bg: transparent;
  --vc-radio-hovered-br: var(--color-42);
  --vc-file-hvr-bg: var(--color-f5);
  --vc-bar-chart-text-fill: var(--color-21);
  --vc-chip-remove-icon-clr: var(--color-30);
  --vc-checkbox-ckd-br-clr: var(--color-3f);
  --vc-date-input-placeholder-clr: var(--color-00);
  --vc-grid-header-link-hvr-clr: var(--color-21);
  --vc-trigger-dlg-bg: var(--color-dc);
  --vc-second-factor-dlg-bg: var(--color-ed);
  --vc-upload-widget-br-clr: var(--color-d0);
  --vc-reset-btn-clr: var(--color-76);
  --vc-upload-dropzone-clr: var(--color-21);
  --vc-upload-dropzone-bg-clr: var(--color-ff);
  --vc-upload-dropzone-hnt-clr: var(--color-21);
  --vc-upload-file-bg-clr: var(--color-dc);
  --vc-upload-file-clr: var(--color-70);
  --vc-form-text-title-clr: var(--color-21);
  --vc-grid-common-br-clr: var(--color-00-1f);
  --vc-popup-bg-clr: var(--color-ed);
  --vc-popup-clr: var(--color-21);
  --vc-popup-a-clr: var(--color-21);
  --vc-toogle-btn-clr: var(--color-21);
  --vc-time-highlight-bg-clr: var(--color-9c);
  --vc-filter-menu-actn-btn-bg-clr: var(--color-dc);
  --vc-filter-menu-btn-primary-disabled-clr: var(--color-70);
  --vc-filter-multiselect-wrap-btn-bg-clr: var(--color-dc);
  --vc-filter-multiselect-wrap-btn-clr: var(--color-70);
  --vc-filter-multiselect-wrap-btn-hvr: var(--color-00-1a);
  --vc-datetime-time-wrap-bg-clr: var(--color-e3);
  --vc-datetime-container-tab-bg-clr: var(--color-ed);
  --vc-checkbox-lbl-clr: var(--color-00-4d);
  --vc-clr-default-clr: var(--color-21);
  --vc-notification-pref-hovered-text: var(--color-21);
  --vc-notification-pref-hovered-bg: var(--color-cb);
  --app-loader-logo: url("/assets/images/logo/vc-logo-vikingcloud.svg");
  --app-loader-text: #0039b3;
}

body,
html {
  background-color: var(--surface);
  color: var(--text-medium-emphasis);
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body ::-webkit-scrollbar,
html ::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
body ::-webkit-scrollbar-track,
html ::-webkit-scrollbar-track {
  background: var(--color-00);
}
body ::-webkit-scrollbar-thumb,
html ::-webkit-scrollbar-thumb {
  background-color: var(--color-53);
  border-radius: 6px;
  border: 2px solid var(--color-00);
}
body ::-webkit-scrollbar-corner,
html ::-webkit-scrollbar-corner {
  background: var(--color-00);
}
body .vc-light-theme ::-webkit-scrollbar-track,
html .vc-light-theme ::-webkit-scrollbar-track {
  background: var(--color-d0);
}
body .vc-light-theme ::-webkit-scrollbar-thumb,
html .vc-light-theme ::-webkit-scrollbar-thumb {
  background-color: var(--color-e5);
  border: 2px solid var(--color-d0);
}
body .vc-light-theme ::-webkit-scrollbar-corner,
html .vc-light-theme ::-webkit-scrollbar-corner {
  background: var(--color-ed);
}

/* Common CSS   */
a {
  color: var(--vc-link-txt);
  text-decoration: none;
}

section.ngx-json-viewer section.segment section.segment-main span.segment-key {
  color: var(--vc-ngx-json-viewer-clr) !important;
}

highcharts-chart:not(.no-title) .highcharts-title {
  left: 0 !important;
  top: 0 !important;
  border-bottom: 2px solid var(--outline);
  padding: 16px 24px !important;
  width: 100% !important;
  align-content: center;
  box-sizing: border-box;
}
highcharts-chart .highcharts-no-data {
  margin-top: 18px;
  left: 2px !important;
  right: 2px !important;
  bottom: 2px !important;
}
highcharts-chart .highcharts-no-data > span {
  left: 16px !important;
  right: 16px !important;
  bottom: 2px !important;
  color: var(--text-high-emphasis) !important;
  font-weight: 400 !important;
  align-content: center;
}
highcharts-chart .highcharts-no-data > span .vc-chart-empty-icon {
  color: var(--text-placeholder) !important;
}