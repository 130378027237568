.mat-horizontal-stepper-header-container {
    padding: 0 16px 0 16px;
    margin-bottom: 8px;

    .mat-step-header {
        padding: 8px;

        .mat-step-icon {
            height: 32px !important;
            width: 32px !important;

            &.mat-step-icon-selected {
                background-color: var(--primary-700) !important;

                .mat-step-icon-content {
                    color: var(--surface);
                }
            }

            &:not(.mat-step-icon-selected) {
                background-color: var(--secondary-600) !important;
                color: var(--surface);
            }
        }

        .mat-step-label {
            padding: 8px 0 0 0 !important;

            .mat-step-text-label {
                font-size: 14px;
                font-weight: 500;
                color: var(--text-low-emphasis);
            }

            &.mat-step-label-selected .mat-step-text-label {
                color: var(--text-high-emphasis);
            }
        }
    }

    .mat-horizontal-stepper-header::after, .mat-horizontal-stepper-header::before {
        top: 38px !important;
        border-color: var(--secondary-300) !important;
        border-top-width: 2px !important;
        width: calc(50% - 36px) !important;
    }

    .mat-stepper-horizontal-line {
        top: 38px !important;
        border-color: var(--secondary-300) !important;
        border-top-width: 2px !important;
    }
}

.mat-horizontal-content-container {
    padding: 0 !important;
}

//.mat-step-header[aria-disabled='true'] {
//    pointer-events: none !important;
//}
//
//.mat-step-header:not([aria-disabled='true']) {
//    &:hover {
//        background-color: transparent !important;
//    }
//}
//
