$base-theme:Material;
$skin-name:viking-cloud;
$swatch-name:Indigo Blue Dark;
$primary-palette-name: indigo !default;
$secondary-palette-name: blue !default;
$theme-type: dark !default;

:root {
  --color-00: #000;
  --color-ff: #fff;
  --color-76: #767676;
  --color-30: #303030;
  --color-42: #424242; /// rgba(66,66,66,1);
  --color-4a: #4a4a4a;
  --color-dc: #dce1ef;
  --color-be: #bebebe;
  --color-70: #707696;
  --color-21: #212a44;
  --color-d0: #d0d4e1;
  --color-42-e5: #424242e5;
  --color-ff-e5: #ffffffe5;
  --color-eb: #ebebeb;
  --color-e1: #e1e3e9;
  --color-ec: #eceef8;
  --color-ed: #edeff5;
  --color-61: #616161;
  --color-d5: #d5d5d5;
  --color-2d: #2d2d2d;
  --color-2e: #2e2e2e;
  --color-59: #599ada;
  --color-3e: #3e4777;
  --color-4d: #4d4d4d;
  --color-9c: #9c9c9c;
  --color-53: #535353;
  --color-40: #4054b2;
  --color-44: #448aff;
  --color-49: #4b9cdb;
  --color-f5: #f5f6fa;
  --color-b7: #b7c1dd;
  --color-07: #0074c9;
  --color-cb: #cbcbcb;
  --color-e5: #e5e8ef;
  --color-37: #37B400;
  --color-00-de: #000000de; // rgba(0, 0, 0, 0.87)
  --color-00-0a: #0000000a; // rgba(0, 0, 0, 0.04)
  --color-00-80: #00000080; // rgba(0, 0, 0, 0.5)
  --color-ff-14: #ffffff14; // rgba(255, 255, 255, 0.08) /// use for rgba(255, 255, 255, 0.07);
  --color-ff-80: #ffffff80; // rgba(255, 255, 255, 0.5)
  --color-e6: #e6e6e6;
  --color-51: #515151;
  --color-de: #dedede;
  --color-ff-1f: #ffffff1f;  // rgba(255, 255, 255, 0.12)
  --color-13: #131313;
  --color-13-00: #13131300; // rgba(19, 19, 19, 0)
  --color-13-f2: #131313f2; // rgba(19, 19, 19, 0.95)
  --color-cf-00: #cfcfcf00; //rgba(207, 207, 207, 0)
  --color-cf-f2: #cfcfcff2; //rgba(207, 207, 207, 0.95)
  --color-cf: #cfcfcf; //rgba(207, 207, 207, 1)
  --color-00-33: #00000033; //rgba(0, 0, 0, 0.20)
  --color-00-14: #00000014; //rgba(0, 0, 0, 0.08)
  --color-00-61: #00000061; // rgba(0, 0, 0, 0.38)
  --color-ff-61: #ffffff61; // rgba(255, 255, 255, 0.38)
  --color-50: #505050;
  --color-ff-0a: #ffffff0a; // rgba(255, 255, 255, 0.04)
  --color-ff-26: #ffffff26; // rgba(255, 255, 255, 0.15)
  --color-48: #484848;
  --color-00-4d: #0000004d; //rgba(0, 0, 0, 0.3);
  --color-4b:  #4b4b4b;  //rgba(75,75,75,1)
  --color-000:  #000000;  //rgba(0,0,0,1)
  --color-ff-b3: #ffffffb3; // rgba(255, 255, 255, 0.7)
  --color-ff-38: #ffffff38; // rgba(255, 255, 255, 0.22)
  --color-ff-40: #ffffff40; // rgba(255, 255, 255, 0.25)
  --color-00-1a: #0000001a;  //rgba(0, 0, 0, 0.1)
  --color-db: #dbe0ed;
  --color-f4: #f4f4f4;
  --color-b0: #b0b6c7;
  --color-ff-cf: #ffffffcf;
  --color-00-1f: #0000001f; //rgba(0, 0, 0, 0.12);
  --color-00-b3: #000000b3; // rgba(0, 0, 0, 0.7);
  --color-00-85: #00000085;  //rgba(0,0,0,0.52);
  --color-00-cf: #000000cf;
  --color-3f: #3f51b5;
  --color-e3: #e3e7f1;
  --color-f5-f5: #f5f5f5; // whitesmoke
  --color-b2: #b2b7c6;
  --color-23: #232A42;
  --color-52: #5262B2;
  --color-71: #717693;
  --color-d1: #d1d4e0;
  --color-dde: #dde1ee;
  --color-fa: #fafbfc;
  --color-d5d: #d5d9e7;
  --color-d5d-99: #d5d9e799;
  --color-ea: #EA2E24;
  --color-d9: #d9534f;
  --color-fe:#fef4f4;
  --color-ff0:#ff0000;
  --color-ea-33: #EA2E2433;
  --color-fa9-33: #FA9F4733;
  --color-ff7: #FF7D00;
  --color-ff7-cc: #ff7d00cc;
  --color-3f-e5: #3f51b5e5;
  --color-68: #6899d5;
  --color-00-a6:#00a650;
  --color-d6: #D60B00;
  --color-e9: #E9EBF7;
  --color-ffe: #FFECE6;
  --color-f4f: #F4FBF9;
  --color-17: #176B5C;
  --color-8d: #8D6A09;
  --color-fef: #FEF8E0;
}
