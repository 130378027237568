:root[data-theme='theme-dark'] {
    --input-text: var(--color-ff);
    --input-border: var(--color-ff-40);
    --input-hovered-border: var(--color-ff-40);
    --textarea-focus-border: var(--color-ff-40);

    --pager-bg: var(--color-42);
    --pager-number-text: var(--color-ff);
    --pager-text: var(--color-ff-b3);
    --pager-number-hover-text: var(--color-ff);
    --pager-number-hover-bg: var(--color-ff-0a);
    --pager-number-focus-bg: var(--color-42);
    --pager-border-width: 1px 0 0;

    --grid-sticky-hovered-bg: var(--color-42);

    --panelbar-header-text: var(--color-ff);
    --panelbar-header-selected-text: var(--color-ff);
    --panelbar-text: var(--color-ff);

    --card-text: var(--color-ff);

    --calendar-text: var(--color-ff);
    --calendar-bg: var(--color-42);
    --calendar-border: var(--color-ff-1f);
    --calendar-navigation-text: var(--color-ff);
    --calendar-navigation-bg: var(--color-42);
    --calendar-navigation-border: var(--color-ff-1f);
    --calendar-cell-hover-text: var(--color-ff);
    --calendar-cell-hover-bg: var(--color-42);
    --calendar-header-cell-text: var(--color-ff-61);

    --hovered-text: var(--color-ff);
    --hovered-bg: var(--color-ff-0a);
    --hovered-border: var(--color-ff-26);

    --tooltip-text: var(--color-ff);
    --tooltip-bg: var(--color-61);
    --tooltip-border: var(--color-61);

    --progressbar-text: var(--color-ff);

    --vc-white-logo-display: block;
    --vc-dark-logo-display: none;

    --vc-topnavigation-txt: var(--color-ff);

    --vc-link-txt: var(--color-ff);

    --vc-form-clr: var(--color-ff);

    --vc-editor-dialog-content-bg: var(--color-30);
    --vc-editor-dialog-buttongroup-bg: var(--color-30);
    --vc-editor-button-bg: var(--color-42);

    --vc-header-bg: var(--color-00);
    --vc-header-bottom-bdr: 1px solid var(--color-30);
    --vc-header-box-shadow: 2px 13px 11px -10px;
    --vc-header-box-shadow-clr: var(--color-00-85);

    --vc-search-header-bg: var(--color-30);

    --vc-custom-scrollbar-track-bg: var(--color-30);
    --vc-webkit-scrollbar-track-bg: var(--color-00);
    --vc-webkit-scrollbar-thumb-bg: var(--color-53);

    --vc-area-disabled-bg: var(--color-42-e5);

    --vc-grid-table-tbody-bg: var(--color-30);
    --vc-ttl-text-clr: var(--color-be);
    --vc-desc-text-clr: var(--color-ff);
    --vc-breadcrumb-box: var(--color-42);

    --vc-progress-bar-bg: var(--color-ff);

    --vc-recent-box-clr: var(--color-59);
    --vc-grid-table-brdr: var(--color-ff-1f);

    --dropdown-multiselect-bg: var(--color-42);
    --dropdown-multiselect-border: var(--color-50);
    --dropdown-multiselect-text-color: var(--color-ff);
    --dropdown-multiselect-chip-bg: var(--color-50);
    --dropdown-multiselect-chip-text: var(--color-ff);
    --dropdown-multiselect-checkbox-checked: var(--color-3f);

    --vc-font-gray-clr: var(--color-be);
    --vc-tabstrip-items-active-bg: var(--color-30);
    --vc-tabstrip-items-bg: var(--color-42);
    --vc-tabstrip-items-clr: var(--color-ff);
    --vc-ttl-card-header-bg: var(--color-42);
    --vc-grid-header-bg: var(--color-42);
    --vc-grid-header-clr: var(--color-ff-b3);
    --vc-grid-header-sort: var(--color-ff-b3);

    --vc-form-merchant-box-content-bg: var(--color-30);
    --vc-chip-filled-bg: var(--color-30);
    --vc-chip-filled-clr: var(--color-ff);

    --vc-grid-txt: var(--color-ff);
    --vc-grid-tr-bg: var(--color-ff-14);

    --vc-grid-bdr: var(--color-ff-1f);
    --vc-grid-clr: var(--color-ff);
    --vc-grid-bg-clr: var(--color-42);

    --vc-chart-surface-bg: var(--color-42);

    --vc-multiselect-wrap-btn-hvr: var(--color-9c);
    --vc-checkbox-hvr-bc: var(--color-ff);

    --vc-grid-state-selected-clr: var(--color-ff);
    --vc-grid-state-selected-bg: var(--color-53);

    --vc-loader-container-bg: var(--color-00-cf);
    --vc-widget-header-bg: linear-gradient(90deg, var(--color-4b) 0%, var(--color-000) 100%);

    --vc-list-point-bt: var(--color-61);
    --vc-list-point-li-clr: var(--color-d5);
    --vc-ttl-point-bb: var(--color-61);

    --vc-value-point-bg: var(--color-2d);
    --vc-value-point-clr: var(--color-ff);

    --vc-sep-line-bb: var(--color-ff-1f);
    --vc-progressbar-state-selected-bg: var(--color-07);
    --vc-round-icon-black-bg: var(--color-00);
    --vc-status-info-bg: var(--color-30);

    --vc-list-items-bt: var(--color-61);
    --vc-list-items-li-clr: var(--color-d5);
    --vc-list-items-li-bb: var(--color-61);

    --vc-check-box-org-bg: var(--color-42);
    --vc-check-box-org-checkbox-fcs-clr: var(--color-ff);
    --vc-check-box-org-checkbox-fcs-bc: var(--color-ff);

    --vc-textbox-container-label-clr: var(--color-cb);
    --vc-textbox-container-label-bg: var(--color-30);

    --vc-tags-bg: var(--color-70);
    --vc-tags-clr: var(--color-00);
    --vc-tags-bc: var(--color-3e);

    --vc-count-box-fw-bg: var(--color-42);
    --vc-count-box-fw-clr: var(--color-ff);

    --vc-advance-search-bg: var(--color-42);
    --vc-advance-search-switch-clr: var(--color-cb);

    --vc-var-base-bg-clr: var(--color-42);
    --vc-var-background-clr: var(--color-42);
    --vc-var-normal-bg-clr: var(--color-42);
    --vc-var-normal-txt-clr: var(--color-ff);
    --vc-var-hover-bg-clr: var(--color-ff-14);
    --vc-var-hover-txt-clr: var(--color-ff);
    --vc-var-gauge-track-bg-clr: var(--color-51);
    --vc-var-chart-inactive-bg-clr: var(--color-ff-80);

    --vc-webkit-autofill-bg: var(--color-30);
    --vc-webkit-text-fill-color: var(--color-ff);

    --vc-action-bg: var(--color-13-00);
    --vc-action-linear-gradient-bg: var(--color-13-f2);
    --vc-action-webkit-gradient-bg: var(--color-13);

    --vc-chart-box-form-bg: var(--color-30);
    --vc-box-auto-contain-bg: var(--color-30);
    --vc-tabstrip-chart-box-bg: var(--color-30);

    --vc-tooltip-text-bg: var(--color-70);
    --vc-tooltip-text-clr: var(--color-00);
    --vc-gray-tag-clr: var(--color-00);

    --vc-calendar-today-bg: var(--color-50);

    --vc-ngx-json-viewer-clr: var(--color-be);

    --vc-chart-box-content-textarea-bg: var(--color-30);

    --vc-radio-hovered-br: var(--color-ff);
    --vc-file-hvr-bg: var(--color-ff-14);

    --vc-bar-chart-text-fill: var(--color-ff);

    --vc-chip-remove-icon-clr: var(--color-ff-b3);

    --vc-checkbox-ckd-br-clr: var(--color-ff);

    --vc-date-input-placeholder-clr: var(--color-ff);

    --vc-grid-header-link-hvr-clr: var(--color-ff);

    --vc-trigger-dlg-bg: var(--color-42);
    --vc-second-factor-dlg-bg: var(--color-42);

    --vc-upload-widget-br-clr: var(--color-ff-1f);
    --vc-reset-btn-clr: var(--color-ff);

    --vc-upload-dropzone-clr: var(--color-ff);
    --vc-upload-dropzone-bg-clr: var(--color-42);
    --vc-upload-dropzone-hnt-clr: var(--color-ff-b3);

    --vc-upload-file-bg-clr: var(--color-42);
    --vc-upload-file-clr: var(--color-ff);

    --vc-form-text-title-clr: var(--color-ff);

    --vc-grid-common-br-clr: var(--color-ff-1f);

    --vc-popup-bg-clr: var(--color-42);
    --vc-popup-clr: var(--color-ff);
    --vc-popup-a-clr: var(--color-ff);

    --vc-toogle-btn-clr: var(--color-ff);
    --vc-time-highlight-bg-clr: var(--color-ff-1f);

    --vc-filter-menu-actn-btn-bg-clr: var(--color-51);
    --vc-filter-menu-btn-primary-disabled-clr: var(--color-00-61);

    --vc-filter-multiselect-wrap-btn-bg-clr: var(--color-4d);
    --vc-filter-multiselect-wrap-btn-clr: var(--color-ff);
    --vc-filter-multiselect-wrap-btn-hvr: var(--color-9c);
    --vc-datetime-time-wrap-bg-clr: var(--color-42);
    --vc-datetime-container-tab-bg-clr: var(--color-42);

    --vc-checkbox-lbl-clr: var(--color-ff-b3);

    --vc-clr-default-clr: var(--color-ff);

    --vc-notification-pref-hovered-text: var(--color-ff);
    --vc-notification-pref-hovered-bg: var(--color-ff-0a);

    --app-loader-logo: url('/assets/images/logo/vc-logo-vikingcloud-dark.svg');
    --app-loader-text: #68a4ff;
}
