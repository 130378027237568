@mixin vc-form-container {
    padding: 16px;
    background-color: var(--background);
    border-radius: 8px;
    box-sizing: border-box;
}

.vc-form-container {
    @include vc-form-container;
    border: 2px solid var(--outline);
}

.vc-form-container-borderless {
    @include vc-form-container;
}
