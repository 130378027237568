// Material UI Form Fields
.vc-mat-form-field.mat-mdc-form-field {
    width: 100%;
    font-size: 14px;
    letter-spacing: normal;

    .mat-mdc-form-field-textarea-control {
        line-height: 1.25;
    }

    &.vc-filter-minimal-styles {
        .mat-mdc-text-field-wrapper {
            background-color: transparent;

            .mat-mdc-form-field-flex {
                background-color: transparent;

                .mat-mdc-form-field-infix input {
                    background-color: transparent !important;
                    -webkit-box-shadow: 0 0 0 0 transparent inset !important;
                }
            }

            .mdc-line-ripple {
                display: none;
            }
        }

        .mat-mdc-text-field-wrapper {
            padding-bottom: 0;

            .mdc-line-ripple::before {
                color: transparent;
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    &:not(.mat-form-field-disabled) {
        mat-label {
            color: var(--text-high-emphasis);
        }

        input {
            background-color: var(--surface) !important;
            -webkit-text-fill-color: var(--text-high-emphasis) !important;
            -webkit-box-shadow: 0 0 0 30px var(--surface) inset !important;
            border-top: none;

            &:hover {
                background-color: var(--outline) !important;
                -webkit-box-shadow: 0 0 0 30px var(--outline) inset !important;
            }
        }

        &:hover {
            .mat-mdc-form-field-flex {
                background-color: var(--outline) !important;
                .mat-mdc-form-field-infix > input {
                    background-color: var(--outline) !important;
                    -webkit-text-fill-color: var(--text-high-emphasis) !important;
                    -webkit-box-shadow: 0 0 0 30px var(--outline) inset !important;
                    border-top: none;
                }
            }
        }

        &:hover input {
            background-color: var(--outline) !important;
            -webkit-text-fill-color: var(--text-high-emphasis) !important;
            -webkit-box-shadow: 0 0 0 30px var(--outline) inset !important;
            border-top: none;
        }

        &.mat-focused {
            .mat-mdc-form-field-flex {
                background-color: var(--primary-50) !important;
                .mat-mdc-form-field-infix {
                    .mdc-floating-label {
                        color: unset;
                    }

                    input {
                        background-color: var(--primary-50) !important;
                        -webkit-text-fill-color: var(--text-high-emphasis) !important;
                        -webkit-box-shadow: 0 0 0 30px var(--primary-50) inset !important;
                        border-top: none;
                    }
                }
            }
        }
    }

    .mdc-text-field--disabled mat-icon {
        color: rgba(0, 0, 0, 0.38);
    }

    .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-focus-overlay {
            background-color: unset;
        }

        .mat-mdc-form-field-flex {
            padding: 0 0.75em;
            background-color: var(--surface);

            .mat-mdc-form-field-infix {
                padding-top: 24px;
                padding-bottom: 6px;
                min-height: auto;

                .mat-mdc-floating-label {
                    font-size: 14px;
                    letter-spacing: 0;
                    top: 26px;
                    width: 100%;
                    will-change: unset;
                }

                .mdc-floating-label--float-above {
                    font-size: 12px !important;
                    transform: translateY(-16px) scale(1) !important;
                }

                input {
                    color: var(--text-high-emphasis);
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }

    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix  {
        height: 32px;
        display: flex;
        align-items: center;

        .mat-mdc-icon-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px !important;
            height: 32px !important;
            padding: 0;
            --mdc-icon-button-icon-color: var(--text-high-emphasis);
        }

        .mat-icon {
            font-size: 21px !important;
            padding: 0 !important;
            height: 21px;
            width: 21px;
        }
    }

    &.mat-form-field-invalid {
        .mat-mdc-form-field-flex {
            background-color: var(--error-background);
            .mat-mdc-form-field-infix {
                .mdc-floating-label {
                    color: var(--error-color) !important;
                    mat-label {
                        color: var(--error-color) !important;
                    }
                }

                input {
                    background-color: var(--error-background);
                    -webkit-text-fill-color: var(--text-high-emphasis) !important;
                    -webkit-box-shadow: 0 0 0 30px var(--error-background) inset !important;
                    border-top: none;
                }
            }

            .mat-mdc-button-wrapper {
                color: var(--error-text-color);
            }
        }

        .vc-error-message {
            color: var(--error-color) !important;
        }
    }

    &.mat-form-field-disabled {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    label, input {
                        cursor: default;
                    }
                }
            }

            .mdc-line-ripple {
                display: none;
            }
        }
    }

    //.mat-chip-input {
    //    color: var(--text-high-emphasis);
    //    font-size: 14px !important;
    //    line-height: 17px;
    //}
    //
    //.mat-select-value {
    //    color: var(--text-high-emphasis);
    //
    //    .mat-select-value-text {
    //        line-height: 17px;
    //    }
    //}
    //
    //&.mat-form-field-disabled {
    //    .mat-form-field-flex {
    //        background-color: var(--surface);
    //    }
    //}
    //
    //.mat-form-field-ripple {
    //    height: 1px;
    //}
    //
    //&.mat-form-field-appearance-fill {
    //    .mat-form-field-subscript-wrapper {
    //        padding-left: 0;
    //    }
    //}
    //
    //
    &:not(.mat-form-field-disabled):not(.mat-form-field-invalid) {
        .mdc-icon-button {
            .mat-icon {
                color: var(--secondary-800);
            }
        }

        .mdc-line-ripple::before {
            color: var(--outline);
            border-bottom-color: var(--outline);
        }

        &:not(.mat-focused) {
            .mat-form-field-ripple {
                background-color: transparent;
            }
        }

        .mdc-floating-label {
            color: var(--text-high-emphasis);

            &:not(.mat-form-field-empty) {
                color: var(--text-low-emphasis);
            }
        }
    }

    .mat-mdc-select-arrow-wrapper {
        .mat-mdc-select-arrow {
            color: var(--secondary-800);
        }
    }

    //}
    //
    //&.mat-form-field-disabled {
    //    color: var(--text-placeholder) !important;
    //
    //    .mdc-floating-label {
    //        color: var(--text-placeholder) !important;
    //    }
    //
    //    .mat-input-element {
    //        color: var(--text-placeholder) !important;
    //    }
    //
    //    .mat-date-range-input-separator {
    //        color: var(--text-placeholder) !important;
    //    }
    //
    //    .mat-chip-input {
    //        color: var(--text-placeholder);
    //    }
    //}
    //
    //&.mat-form-field-readonly {
    //    color: var(--text-medium-emphasis) !important;
    //
    //    .mdc-floating-label {
    //        color: var(--text-low-emphasis) !important;
    //    }
    //
    //    .mat-form-field-ripple {
    //        background-color: transparent !important;
    //    }
    //
    //    .mat-input-element {
    //        color: var(--text-medium-emphasis) !important;
    //    }
    //
    //    .mat-date-range-input-separator {
    //        color: var(--text-medium-emphasis) !important;
    //    }
    //
    //    .mat-chip-input {
    //        color: var(--text-medium-emphasis);
    //    }
    //}
    //

    .mat-mdc-form-field-hint-wrapper {
        position: relative;
        padding: 0;

        .mat-mdc-form-field-hint {
            display: flex;
            padding-top: 4px;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: normal;
            min-height: 15px;
        }
    }

    &.no-padding-bottom {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.mat-mdc-form-field .mat-mdc-form-field-bottom-align {
    min-height: 16px;

    &::before {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}
