.vc-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1001;
    background-color: var(--background, #f3f3f3);
    transition: visibility 0.2s linear, opacity 0.2s ease-in;

    .vc-loader-logo {
        height: 40px;
        width: 403px;
        background-image: var(--app-loader-logo, url('/assets/images/logo/vc-logo-vikingcloud.svg'));
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    .vc-loading-text {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: var(--app-loader-text, #0039b3);
        font-feature-settings: 'clig' off, 'liga' off;

        &:last-child {
            margin-right: 0;
        }

        & span {
            &::before {
                content: 'Loading';
            }
        }

        .vc-dot-pulse {
            position: relative;
            left: -9999px;
            top: 5px;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: var(--app-loader-text);
            color: var(--app-loader-text);
            box-shadow: 9999px 0 0 -5px;
            animation: vc-dot-pulse 1.5s infinite linear;
            animation-delay: 0.25s;
        }
        .vc-dot-pulse::before,
        .vc-dot-pulse::after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 2px;
        }
        .vc-dot-pulse::before {
            box-shadow: 9994px 0 0 -5px;
            animation: vc-dot-pulse-before 1.5s infinite linear;
            animation-delay: 0s;
        }
        .vc-dot-pulse::after {
            box-shadow: 10004px 0 0 -5px;
            animation: vc-dot-pulse-after 1.5s infinite linear;
            animation-delay: 0.5s;
        }

        @keyframes vc-dot-pulse-before {
            0% {
                box-shadow: 9994px 0 0 -5px;
            }
            30% {
                box-shadow: 9994px 0 0 1px;
            }
            60%,
            100% {
                box-shadow: 9994px 0 0 -5px;
            }
        }
        @keyframes vc-dot-pulse {
            0% {
                box-shadow: 9999px 0 0 -5px;
            }
            30% {
                box-shadow: 9999px 0 0 1px;
            }
            60%,
            100% {
                box-shadow: 9999px 0 0 -5px;
            }
        }
        @keyframes vc-dot-pulse-after {
            0% {
                box-shadow: 10004px 0 0 -5px;
            }
            30% {
                box-shadow: 10004px 0 0 1px;
            }
            60%,
            100% {
                box-shadow: 10004px 0 0 -5px;
            }
        }
    }
}
