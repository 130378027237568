$spaces-list: (
    0: 0,
    4: 4px,
    8: 8px,
    12: 12px,
    16: 16px,
    20: 20px,
    24: 24px,
    28: 28px,
    32: 32px,
    36: 36px,
    40: 40px,
    44: 44px,
    48: 48px,
    52: 52px,
    56: 56px
);

@each $name, $value in $spaces-list {
    .space-#{$name} {
        margin: $value;
    }

    .space-bottom-#{$name} {
        margin-bottom: $value;
    }

    .space-top-#{$name} {
        margin-top: $value;
    }

    .space-left-#{$name} {
        margin-left: $value;
    }

    .space-right-#{$name} {
        margin-right: $value;
    }

    .space-x-#{$name} {
        margin-left: $value;
        margin-right: $value;
    }

    .space-y-#{$name} {
        margin-top: $value;
        margin-bottom: $value;
    }

    .space-pd-#{$name} {
        padding: $value;
    }

    .space-pd-bottom-#{$name} {
        padding-bottom: $value;
    }

    .space-pd-top-#{$name} {
        padding-top: $value;
    }

    .space-pd-left-#{$name} {
        padding-left: $value;
    }

    .space-pd-right-#{$name} {
        padding-right: $value;
    }

    .space-pd-x-#{$name} {
        padding-left: $value;
        padding-right: $value;
    }

    .space-pd-y-#{$name} {
        padding-top: $value;
        padding-bottom: $value;
    }
}
