// Material UI Chips
.mdc-evolution-chip-set__chips {
    width: 100%;
}

.mat-mdc-chip {
    max-width: calc(100% - 16px) !important;
    height: 28px !important;
    font-weight: 500 !important;
    letter-spacing: normal !important;
    border: 1px solid var(--text-high-emphasis);
    background-color: var(--background) !important;
    padding-left: 8px;

    .mdc-evolution-chip__cell--primary {
        width: calc(100% - 34px) !important;
        padding-left: 0 !important;

        .mat-mdc-chip-action-label {
            overflow: hidden;
            display: flex;
            align-items: center;

            .mat-icon {
                font-size: 18px;
                min-width: 19px;
                width: 18px;
                height: 18px;
            }
        }
    }

    //&.mat-accent {
    //    color: var(--text-high-emphasis) !important;
    //    .mat-chip-ripple {
    //        border: 1px solid var(--secondary-800) !important;
    //    }
    //    .mat-chip-remove.mat-chip-trailing-icon {
    //        .mat-icon {
    //            color: var(--text-high-emphasis) !important;
    //        }
    //    }
    //}

    //&.mat-primary {
    //    color: var(--primary-500) !important;
    //    .mat-chip-ripple {
    //        border: 1px solid var(--primary-500) !important;
    //    }
    //    .mat-chip-remove.mat-chip-trailing-icon {
    //        .mat-icon {
    //            color: var(--primary-500) !important;
    //        }
    //    }
    //}
    //
    //&.mat-warn {
    //    color: var(--error-color) !important;
    //    .mat-chip-ripple {
    //        border: 1px solid var(--error-color) !important;
    //    }
    //    .mat-chip-remove.mat-chip-trailing-icon {
    //        .mat-icon {
    //            color: var(--error-color) !important;
    //        }
    //    }
    //}

    &:hover {
        background-color: var(--outline) !important;
    }
}
