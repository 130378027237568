@use '@angular/material' as mat;
@use 'typography' as typography;

$color-primary-500: #0055ff;
$color-primary-50: #f0f7ff;
$color-primary-100: #b6d8ff;
$color-primary-200: #90caff;
$color-primary-300: #68a4ff;
$color-primary-400: #3a6aff;
$color-primary-600: #0043ce;
$color-primary-700: #0039b3;
$color-primary-800: #003099;
$color-primary-900: #001e60;

$color-secondary-800: #062539;
$color-secondary-900: #001223;
$color-secondary-700: #14374e;
$color-secondary-600: #244964;
$color-secondary-500: #325775;
$color-secondary-400: #4b6a86;
$color-secondary-300: #607b95;
$color-secondary-200: #7e95ab;
$color-secondary-100: #9aafc2;
$color-secondary-50: #bac6d8;

$vc-primary-palette: (
    50: $color-primary-50,
    100: $color-primary-100,
    200: $color-primary-200,
    300: $color-primary-300,
    400: $color-primary-400,
    500: $color-primary-500,
    600: $color-primary-600,
    700: $color-primary-700,
    800: $color-primary-800,
    900: $color-primary-900,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$vc-accent-palette: (
    50: $color-secondary-50,
    100: $color-secondary-100,
    200: $color-secondary-200,
    300: $color-secondary-300,
    400: $color-secondary-400,
    500: $color-secondary-500,
    600: $color-secondary-600,
    700: $color-secondary-700,
    800: $color-secondary-800,
    900: $color-secondary-900,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$vc-warn-palette: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #d60b00,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b71c1c,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

$vc-primary: mat.m2-define-palette($vc-primary-palette, 500, 800, 200);
$vc-accent: mat.m2-define-palette($vc-accent-palette, 800, 500, 600);
$vc-warn: mat.m2-define-palette($vc-warn-palette);
$vc-typography: mat.m2-define-typography-config(
    $font-family: typography.$font-family-poppins,
);
$vc-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $vc-primary,
            accent: $vc-accent,
            warn: $vc-warn,
        ),
        typography: $vc-typography,
    )
);

:root[data-theme='theme-light'] {
    //Palette Colors
    --primary-500: #0055ff;
    --primary-50: #f0f7ff;
    --primary-100: #b6d8ff;
    --primary-200: #90caff;
    --primary-300: #68a4ff;
    --primary-400: #3a6aff;
    --primary-600: #0043ce;
    --primary-700: #0039b3;
    --primary-800: #003099;
    --primary-900: #001e60;

    --secondary-800: #062539;
    --secondary-900: #001223;
    --secondary-700: #14374e;
    --secondary-600: #244964;
    --secondary-500: #325775;
    --secondary-400: #4b6a86;
    --secondary-300: #607b95;
    --secondary-200: #7e95ab;
    --secondary-100: #9aafc2;
    --secondary-50: #bac6d8;

    // General colors
    --shadow-color: rgba(35, 42, 66, 0.15);
    --background: #fff;
    --surface: #f3f3f3;
    --outline: #dadbdc;
    --ghost-white: #f6f8fb;
    --text-high-emphasis: #052133;
    --text-medium-emphasis: #37454f;
    --text-low-emphasis: #657079;
    --text-placeholder: #aaaaaa;

    // Notification colors
    --warning-color: #F8CF33;
    --warning-background: #FEF8E0;
    --warning-text-color: #8D6A09;

    --error-color: #D92C26;
    --error-background: #FFECE6;
    --error-text-color: #C20A00;

    --success-color: #1C8270;
    --success-background: #F4FBF9;
    --success-text-color: #176B5C;

    --info-color: #3f51b5;
    --info-background: #e9ebf7;
    --info-text-color: #3f51b5;
}
