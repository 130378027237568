@use '@angular/material' as mat;
@use 'themes/light-theme' as light;
@use 'themes/dark-theme' as dark;
@use '@angular/cdk/overlay-prebuilt.css';

$config: mat.m2-define-typography-config(
    $body-1: mat.m2-define-typography-level(
        $font-size: 14px,
        $line-height: 14px,
        $font-weight: 400,
        $font-family: "'Poppins', sans-serif",
        $letter-spacing: normal
    ),
);

@use './components/form-field';
@use './components/date-picker';
@use './components/chips';
@use './components/table';
@use './components/stepper';
@use './common-styles';
@use './spacer';
@use './common-mixins';

@include mat.all-component-typographies($config);
@include mat.core();
@include mat.all-component-themes(dark.$vc-theme);
.vc-light-theme {
    @include mat.all-component-colors(light.$vc-theme);
}

/** Tooltip **/
.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
        line-height: 16px;
        word-break: break-word;

        --mdc-plain-tooltip-container-color: var(--secondary-800);
        --mdc-plain-tooltip-supporting-text-color: var(--primary-50);
        --mdc-plain-tooltip-supporting-text-size: 12px;
        --mdc-plain-tooltip-supporting-text-weight: 400;
        --mdc-plain-tooltip-supporting-text-tracking: 0;
    }
}


/** Select **/
.mat-mdc-select {
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-tracking: 0;
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    border: 1px solid var(--outline);
    background-color: var(--background);
    padding: 0 !important;

    .mat-mdc-option {
        color: var(--text-high-emphasis) !important;
        font-size: 14px !important;
        min-height: 42px;
        letter-spacing: normal;
        width: calc(100% - 32px);

        &:hover {
            background-color: var(--outline) !important;
        }

        &.mdc-list-item {
            .mdc-list-item__primary-text {
                width: calc(100% - 24px);
            }

            &--selected {
                background-color: var(--primary-50) !important;

                .mdc-list-item__primary-text {
                    color: var(--text-high-emphasis) !important;
                    letter-spacing: normal !important;
                }
            }
        }

        .mat-pseudo-checkbox-checked,
        .mat-pseudo-checkbox-indeterminate {
            color: var(--primary-600) !important;
        }
    }
}

.mat-mdc-select-panel, mat-mdc-autocomplete-panel {
    border-radius: 4px !important;
    padding: 0 !important;

    .mat-mdc-option {
        min-height: 42px !important;
        height: 42px !important;
    }
}

.mat-mdc-list-item {
    height: 42px !important;
}

.mdc-list-item__start {
    margin-right: 8px !important;
}

.mat-mdc-list-base {
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-label-text-tracking: 0;
}

.mat-mdc-select-panel {
    min-width: 180px;
}


/** Chips Autocomplete **/
.mat-mdc-chip-set input.mat-mdc-chip-input {
    flex: 1 0 auto;
    width: 100%;
}


/** Badge **/
.mat-badge-content {
    background-color: var(--error-background) !important;
    color: var(--error-text-color) !important;
    border: 1px solid var(--error-text-color) !important;
}


/** Button **/
.mat-mdc-icon-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;

    .mat-mdc-button-persistent-ripple {
        border-radius: 4px !important;
    }

    .mat-mdc-button-touch-target {
        height: 40px !important;
        width: 40px !important;
    }
}

vc-button .mat-badge-content {
    left: 90% !important;
    bottom: 90% !important;
}

.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-raised-button {
    border-radius: 4px !important;
    --mat-mdc-button-persistent-ripple-color: var(--primary-50) !important;

    .mdc-button__label {
        white-space: nowrap;
    }
}

/** Checkbox **/
@include mat.checkbox-overrides((
    disabled-label-color: var(--text-high-emphasis),
    selected-checkmark-color: var(--primary-50),
    selected-focus-icon-color: var(--secondary-900),
    selected-hover-icon-color: var(--secondary-900),
    selected-icon-color: var(--secondary-800),
    selected-pressed-icon-color: var(--secondary-900),
    unselected-focus-icon-color: var(--secondary-800),
    unselected-hover-icon-color: var(--secondary-800),
    disabled-selected-icon-color: var(--secondary-300),
    disabled-unselected-icon-color: var(--secondary-300),
    unselected-icon-color: var(--secondary-800),
    label-text-tracking: normal,
    label-text-weight: 400,
));

.mat-mdc-checkbox {
    height: 24px;

    .mdc-checkbox {
        padding: 0 !important;
        height: 18px;
        width: 18px;
        flex: 0 0 18px;

        .mat-mdc-checkbox-touch-target {
            height: 18px;
            width: 18px;
        }

        .mdc-checkbox__native-control {
            height: 18px !important;
            width: 18px !important;
        }

        .mdc-checkbox__background {
            top: 0 !important;
            left: 0 !important;
        }
    }

    label {
        margin-left: 4px !important;
    }
}

/** Radio Button **/
.mat-mdc-radio-group {
    --mdc-radio-state-layer-size: 20px;
    --mat-radio-label-text-line-height: 20px;
    --mat-radio-label-text-tracking: 0;
    --mat-radio-label-text-size: 14px;
    --mat-radio-label-text-line-weight: 400;

    label {
        font-style: normal;
        text-decoration: none;
        text-transform: none;
        margin-left: 4px !important;
    }

    .mat-mdc-radio-touch-target {
        height: 32px;
        width: 32px;
    }
}


/** Toggle **/
vc-toggle {

    .mat-mdc-slide-toggle {
        height: 24px;
        margin-bottom: 8px;

        .mdc-label {
            font-size: 14px !important;
            font-weight: 400 !important;
            font-style: normal;
            line-height: 20px !important;
            letter-spacing: 0 !important;
            text-decoration: none;
            text-transform: none;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .mdc-switch {
            box-sizing: content-box;
            border-radius: 12px;
            height: 24px;
            width: 44px;
            margin-right: 4px;

            --mdc-switch-unselected-icon-size: 14px;
            --mdc-switch-selected-icon-size: 20px;

            .mdc-switch__track {
                height: 100%;
                border-radius: 18px;
                box-sizing: border-box;
                width: 44px;

                --mdc-switch-selected-track-color: var(--primary-500) !important;
                --mdc-switch-unselected-track-color: var(--surface) !important;
                --mdc-switch-selected-hover-track-color: var(--primary-500) !important;
                --mdc-switch-unselected-hover-track-color: var(--surface) !important;
                --mdc-switch-selected-focus-track-color: var(--primary-500) !important;
                --mdc-switch-unselected-focus-track-color: var(--surface) !important;
                --mdc-switch-disabled-selected-track-color: var(--primary-500) !important;
                --mdc-switch-disabled-unselected-track-color: var(--surface) !important;
                --mdc-switch-disabled-track-opacity: 1;

                --mdc-switch-selected-icon-color: var(--primary-50) !important;
                --mdc-switch-unselected-icon-color: var(--secondary-500) !important;
            }

            .mdc-switch__handle-track {
                --mdc-switch-state-layer-size: 40px;
                --mdc-switch-disabled-selected-icon-opacity: 1;
            }

            .mdc-switch__icon {
                display: none;
            }

            &.mdc-switch--unselected {
                background-color: var(--surface);
                --mdc-switch-handle-width: 14px;
                --mdc-switch-handle-height: 14px;

                .mdc-switch__track::before {
                    border: 1px solid var(--secondary-500) !important;
                }

                .mdc-switch__handle-track {
                    left: 5px;
                    --mat-switch-with-icon-handle-size: 14px;
                }

                .mdc-switch__icons {
                    background-color: var(--secondary-500) !important;
                }
            }

            &.mdc-switch--selected {
                background-color: var(--primary-500);
                --mdc-switch-handle-width: 20px;
                --mdc-switch-handle-height: 20px;

                .mdc-switch__track::after {
                    border: 1px solid var(--secondary-500) !important;
                }

                .mdc-switch__handle-track {
                    display: flex;
                    align-items: center;
                    top: unset;
                    left: 4px;
                    transform: translateX(18px) !important;
                    --mat-switch-with-icon-handle-size: 20px;
                }

                .mdc-switch__icons {
                    background-color: var(--primary-50) !important;
                }
            }

            &:disabled {
                opacity: 0.5;
            }
        }

        .mdc-switch__handle-track {
            top: 3px;
            height: 19px;
            width: 19px;

            .mdc-switch__handle {
                .mdc-switch__icons {
                    border-radius: 50%;
                }
            }
        }
    }
}

/** File Uploader **/
.vc-file-uploader-drop-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
    box-sizing: border-box;
    border: 3px dashed var(--outline);
    border-radius: 8px;
    z-index: 998;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
}

.vc-file-uploader-drop-zone-mask {
    visibility: visible;
    cursor: grabbing;
}


/** Tab Group and Directive **/
.mat-mdc-tab-header {
    border: 2px solid var(--outline);
    border-bottom: 2px solid var(--outline) !important;
    border-radius: 8px;
    background-color: var(--background);

    .mat-mdc-tab-list {
        padding-left: 24px;
        padding-right: 24px;
    }

    .mat-mdc-tab-labels {
        padding-right: 48px;

        .mat-mdc-tab {
            min-width: 80px;
            opacity: 1;
            padding: 0 24px;
            flex-grow: 0 !important;

            &.cdk-focused {
                background-color: var(--primary-50) !important;
            }

            .mdc-tab__text-label {
                color: var(--text-low-emphasis) !important;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: normal;
                height: 100%;
                width: 100%;
                padding: 0 12px;

                > .mat-badge .mat-badge-content {
                    background-color: var(--error-background) !important;
                    color: var(--error-text-color) !important;
                    border: 1px solid var(--error-text-color) !important;
                    right: -20px;
                    text-overflow: clip;
                    border-radius: 18px;
                    top: 13px;
                }
            }

            .vc-tabs-link-header {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--text-low-emphasis);
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: normal;
                height: 100%;
                width: 100%;
            }

            &.mdc-tab--active {
                .mat-badge {
                    color: var(--text-high-emphasis) !important;
                }
            }

            .mat-ripple-element, .mdc-tab__ripple::before {
                background-color: #000;
            }

            &:hover {
                background-color: var(--outline);

                .mat-badge {
                    color: var(--text-high-emphasis) !important;
                }
            }
        }
    }
}

/** Dialog **/
.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
        --mdc-dialog-container-color: var(--background);
        border: 1px solid var(--outline);
    }

    .mat-mdc-dialog-title {
        --mdc-dialog-subhead-tracking: normal;
    }

    .mat-mdc-dialog-content {
        padding-top: 0 !important;
    }

    .mat-mdc-dialog-actions {
        padding: 14px 24px !important;
    }
}

/** Dialog - Side Panel **/
.vc-side-panel {
    .mat-mdc-dialog-container {
        &.mdc-dialog--open {
            .mat-mdc-dialog-inner-container {
                animation: slideInFromRight 0.3s ease-in-out;
                transition: none;  // eliminates Angular Material's default dialog transition on open
            }
        }

        &.mdc-dialog--closing {
            .mat-mdc-dialog-inner-container {
                animation: slideBackToRight 0.3s ease-in-out;
                opacity: 1; // eliminates Angular Material's default dialog transition on close
                transition-delay: 0.2s;  // prevents brief flicker from animation cycling
            }
        }
    }

    .mat-mdc-dialog-surface {
        border-radius: 0;
        transition: none;

        .vc-dialog-wrapper {
            .vc-dialog-header {
                box-sizing: border-box;
                border-bottom: 1px solid var(--outline);
            }

            .mat-mdc-dialog-content {
                max-height: calc(100vh - 130px);
            }
        }
    }

    .mat-mdc-dialog-actions {
        // bias actions to the bottom of side panel
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        border-top: 1px solid var(--outline);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideBackToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}


/** Pick List **/
vc-pick-list {
    .mat-mdc-list-item {
        display: flex;
        font-size: 14px;
        height: 3em;
        background-color: var(--background);

        &:hover {
            background-color: var(--primary-50);
        }

        .mdc-list-item__content {
            padding: 0 16px;

            .mdc-list-item__primary-text {
                color: var(--text-high-emphasis) !important;
            }
        }
    }
}

/** Chart Empty **/
.vc-chart-empty-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--background);

    .vc-chart-no-data {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding: 8px 0;

        .vc-chart-empty-icon {
            color: var(--text-placeholder);
        }

        .vc-chart-no-data-text {
            color: var(--text-high-emphasis);
        }
    }

    .vc-chart-empty-actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
    }

    .vc-chart-empty-background {
        background-size: 100% 100%;
        background-image: url('data:image/svg+xml,<svg width="100%" height="80px" viewBox="0 0 306 81" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_9003_5184)"><path d="M65.5425 9.69541C49.8358 23.1239 26.2759 -16.5511 0.574097 9.69541V80.5H305.426V9.69541C281.866 10.3058 231.176 -6.41874 216.898 9.69541C199.049 29.8381 158.355 -3.73305 148.359 9.69541C138.364 23.1239 81.249 -3.73305 65.5425 9.69541Z" fill="url(%23paint0_linear_9003_5184)" /><path d="M0.574097 11.047C26.2759 -15.1995 49.8358 24.4755 65.5425 11.047C81.249 -2.38144 138.364 24.4755 148.359 11.047C158.355 -2.38144 199.049 31.1897 216.898 11.047C231.176 -5.06713 281.866 11.6574 305.426 11.047" stroke="%23E4E6F0" stroke-width="2" /></g><defs><linearGradient id="paint0_linear_9003_5184" x1="153" y1="-27.538" x2="153" y2="80.5" gradientUnits="userSpaceOnUse"><stop stop-color="%23F2F3F7" offset="0" /><stop offset="1" stop-color="%23F2F3F7" stop-opacity="0" /></linearGradient><clipPath id="clip0_9003_5184"><rect width="306" height="80" fill="white" transform="translate(0 0.5)" /></clipPath></defs></svg>');
    }
}

/** Overlay (menus/dialogs/dropdowns) **/
.cdk-overlay-pane {
    --mat-menu-item-label-text-size: 14px !important;
    --mat-menu-item-label-text-tracking: normal !important;
    --mat-menu-container-color: var(--background);
    --mdc-dialog-supporting-text-size: 14px;

    div.mat-mdc-menu-panel {
        border: 1px solid var(--outline);
        max-width: unset !important;

        .mat-mdc-menu-item-text {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .mdc-menu-surface.mat-mdc-autocomplete-panel {
        min-width: 160px;
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}
