// Material UI Date Picker
.mat-calendar {
    background-color: var(--surface);
    border: 1px solid var(--outline);
    border-radius: 4px;

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
        color: var(--text-high-emphasis) !important;

        .mat-calendar-body-cell-content.mat-calendar-body-today {
            border-color: var(--primary-700) !important;
        }

        .mat-calendar-body-selected {
            background-color: var(--primary-700) !important;
            color: var(--primary-50) !important;
        }

        &.mat-calendar-body-in-range {

            &::before {
                background-color: transparent;
            }

            .mat-calendar-body-cell-content {
                background-color: var(--primary-700) !important;
                color: var(--primary-50) !important;
            }
        }

        &:hover {
            .mat-calendar-body-cell-content {
                background-color: var(--primary-500) !important;
                color: var(--primary-50) !important;
            }
        }
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
        &.mat-calendar-body-selected {
            background-color: var(--primary-700) !important;
            color: var(--primary-50) !important;
        }

        &:hover {
            .mat-calendar-body-cell-content {
                background-color: var(--primary-500) !important;
                color: var(--primary-50) !important;
            }
        }
    }
}
